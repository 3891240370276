export const skills = [
  {
    skill: "HTML / CSS",
    type: "frontend",
    progress: 100,
    url: "",
  },
  {
    skill: "JS / jQuery",
    type: "frontend",
    progress: 100,
    url: "",
  },
  {
    skill: "React",
    type: "frontend",
    progress: 95,
    url: "",
  },
  {
    skill: "Angular",
    type: "frontend",
    progress: 85,
    url: "",
  },
  {
    skill: "Next.js",
    type: "frontend",
    progress: 80,
    url: "",
  },
  {
    skill: "Redux",
    type: "frontend",
    progress: 90,
    url: "",
  },
  {
    skill: "Bootstrap",
    type: "frontend",
    progress: 100,
    url: "",
  },
  {
    skill: "SASS",
    type: "frontend",
    progress: 65,
    url: "",
  },
  {
    skill: "Material UI",
    type: "frontend",
    progress: 85,
    url: "",
  },
  {
    skill: "GraphQL",
    type: "frontend",
    progress: 65,
    url: "",
  },
  {
    skill: "C#",
    type: "backend",
    progress: 95,
    url: "",
  },
  {
    skill: ".NET",
    type: "backend",
    progress: 85,
    url: "",
  },
  {
    skill: "Node.js",
    type: "backend",
    progress: 95,
    url: "",
  },
  {
    skill: "MySQL",
    type: "backend",
    progress: 85,
    url: "",
  },
  {
    skill: "MongoDB",
    type: "backend",
    progress: 80,
    url: "",
  },
  {
    skill: "Express.js",
    type: "backend",
    progress: 85,
    url: "",
  },
  {
    skill: "Docker",
    type: "other",
    progress: 55,
    url: "",
  },
  {
    skill: "Entity Framework",
    type: "backend",
    progress: 80,
    url: "",
  },
  {
    skill: "API Development",
    type: "backend",
    progress: 80,
    url: "",
  },
  {
    skill: "Agile",
    type: "other",
    progress: 70,
    url: "",
  },

  {
    skill: "UI / UX Design",
    type: "graphic",
    progress: 80,
    url: "",
  },
  {
    skill: "Canva",
    type: "graphic",
    progress: 85,
    url: "",
  },
  {
    skill: "Photoshop",
    type: "graphic",
    progress: 70,
    url: "",
  },
  {
    skill: "Illustrator",
    type: "graphic",
    progress: 70,
    url: "",
  },
  {
    skill: "MVC Development",
    type: "backend",
    progress: 70,
    url: "",
  },
  {
    skill: "GSAP",
    type: "other",
    progress: 80,
    url: "",
  },
  {
    skill: "Figma",
    type: "graphic",
    progress: 60,
    url: "",
  },
  {
    skill: "3rd-party API",
    type: "other",
    progress: 90,
    url: "",
  },
  {
    skill: "Git/ GitHub",
    type: "other",
    progress: 85,
    url: "",
  },
  {
    skill: "ADS",
    type: "other",
    progress: 85,
    url: "",
  },
  {
    skill: "AWS",
    type: "other",
    progress: 80,
    url: "",
  },
];