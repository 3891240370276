import "./Contact.css";
import { useEffect } from "react";
import gsap from "gsap";
import { navAnimation } from "../../utils/Animate";

/* Components */
import Navigation from "../../components/Navigation/Navigation";
import Footer from "../../components/Footer/Footer";
import ThankYouContent from "../../components/Contact/ThankYou";

const ThankYou = () => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      navAnimation();
    });
    return () => ctx.revert();
  });

  return (
    <div id="thankYouDiv">
      <Navigation page="thankYou" />
      <ThankYouContent />
      <Footer page="thankYou"/>
    </div>
  );
};

export default ThankYou;
