import "./Footer.css";

import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = (props) => {

  const page = props.page;

  return (
    <div id="footerDiv" className={`footer-${page}`}>
      <Row>
        <Col className="col" xs={12} sm={6}>
          <p style={{ fontWeight: "bold" }}>A TO ZION WEB DESIGN, LLC</p>
          <p className="no-margin">
            <a href="mailto:ashley.stith@atozionwebdesign.com">
              <i className="bi bi-envelope"></i>{" "}
              ashley.stith@atozionwebdesign.com
            </a>
          </p>
          <p className="no-margin">
            <i className="bi bi-telephone"></i> 202.681.3589
          </p>
        </Col>

        <Col className="footer-right col" xs={12} sm={6}>
        <a
            href={require("../../documents/Resume-Stith,Ashley-Jul24.pdf")}
            alt=""
            target="_blank"
            rel="noreferrer"
          >
            <p className="no-margin">RESUME</p>
          </a>
          <p>
            <Link to={`/contact`}>CONTACT</Link>
          </p>
          <p className="no-margin ">
            <a
              href="https://github.com/atozionwebdesign"
              alt=""
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-github" style={{ marginRight: "25px" }}></i>
            </a>
            <a
              href="https://www.linkedin.com/in/ashleystith/"
              alt=""
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-linkedin"></i>
            </a>
          </p>
        </Col>
      </Row>
      <div id="copyrightDiv">
        <p id="copyright">
          <i className="bi bi-c-circle"></i> 2024 A To Zion Web Design, LLC |
          All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
