const INITIAL_STATE = {
    inquiries: []
  }

const rootReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
      case 'GET_INQUIRIES':
        return {
            inquiries: action.inquiries
        };
        default : return {
            ...state
        }
    }
  }

export default rootReducer;