import "./Contact.css";
import {Row, Col} from "react-bootstrap";

const ThankYou = () => {

    return(
    <Row id="thankYou" className="justify-content-center align-items-center no-margin">
        <Col>
        
        <div className="jumbo-text-solid teal contactHeader">
        Thank You
        </div>
        <p className="teal">Your email has been received. I will get back to you soon.</p>
        </Col>
    </Row>
    )
}

export default ThankYou;