/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

export default {
  getAllInquiries: async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/inquiries`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  createInquiry: async (inquiry) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/new-inquiry`, inquiry);
    } catch (error) {
      console.log(error);
    }
  },
};