import "./Contact.css";
import { useRef, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import API from "../../redux/API";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const contactForm = useRef();
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const fetchAllInquiries = async () => {
    const res = await API.getAllInquiries();
    return res;
  };

  const createInquiry = (length) => {
    const inquiryData = {
      id : length + 1,
      userName: contactForm.current.userName.value,
      company: contactForm.current.company.value,
      email: contactForm.current.email.value,
      phone: contactForm.current.phone.value,
      subject: contactForm.current.subject.value,
      message: contactForm.current.message.value,
    };
    API.createInquiry(inquiryData).then(sendEmail(inquiryData));
  }

  const sendEmail = (data) => {
   
    const templateParams = {
      id: data.id,
      name: data.userName,
      company: data.company,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
    };
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
          navigate("/thank-you")
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
      e.stopPropagation();
    if (e.currentTarget.checkValidity() === false) {
      
      setValidated(true);
    } else{
       fetchAllInquiries().then(result => 
        createInquiry(result.length)
      )
    }
      
  };

  return (
    <div id="contact">
      <div className="contactHeaderDiv">
        <Row className="justify-content-center align-items-center">
          <Col
            id="contactMsg"
            xs={{ span: 12, order: 2 }}
            sm={{ span: 8, order: 1 }}
          >
            <div className="jumbo-text-solid yellow contactHeader">
              HELLO
            </div>
            <p className="dark-gray">
              I am ready to get to work! Send me a message with questions or
              project ideas and I will be in touch.
            </p>
          </Col>
          <Col xs={{ span: 12, order: 1 }} sm={{ span: 4, order: 2 }}>
            <img
              src={require("../../assets/contact/ash-contact-img.png")}
              alt=""
              id="ashContactImg"
            />
          </Col>
        </Row>
      </div>
      <div id="formContainer">
        <Row>
          <Col
            xs={12}
            style={{
              borderRadius: "5px 0 0 5px",
              textAlign: "left",
            }}
          >
            <Form
              id="contactForm"
              ref={contactForm}
              onSubmit={handleFormSubmit}
              noValidate
              validated= {validated}
            >
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-5">
                    <Form.Label>
                      NAME{" "}
                      <span
                        style={{ marginLeft: "10px", fontWeight: "normal" }}
                      >
                        <sup>REQUIRED</sup>
                      </span>
                    </Form.Label>
                    <Form.Control required type="text" name="userName"></Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-5">
                    <Form.Label>COMPANY NAME </Form.Label>
                    <Form.Control type="text" name="company"></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-5">
                    <Form.Label>
                      EMAIL
                      <span
                        style={{ marginLeft: "10px", fontWeight: "normal" }}
                      >
                        <sup>REQUIRED</sup>
                      </span>
                    </Form.Label>
                    <Form.Control required type="email" name="email"></Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-5">
                    <Form.Label>PHONE</Form.Label>
                    <Form.Control type="text" name="phone"></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-5">
                <Form.Label>SUBJECT</Form.Label>
                <Form.Control type="text" name="subject"></Form.Control>
              </Form.Group>
              <Form.Group className="mb-5">
                <Form.Label>MESSAGE</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={3}
                ></Form.Control>
              </Form.Group>
              <div className="right">
                <Button className="primary-btn" type="submit">
                  SEND <i className="bi bi-arrow-right"></i>
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
