import "./Contact.css";
import { useEffect } from "react";
import gsap from "gsap";
import { navAnimation } from "../../utils/Animate";

/* Components */
import Navigation from "../../components/Navigation/Navigation";
import Footer from "../../components/Footer/Footer";
import ContactContent from "../../components/Contact/Contact";

const Contact = () => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      navAnimation();
    });
    return () => ctx.revert();
  });

  return (
    <div id="contactDiv">
      <Navigation page="contact" />
      <ContactContent />
      <Footer page="contact" />
    </div>
  );
};

export default Contact;
