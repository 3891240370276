import "./Kwame.css";
import React from "react";
import {Image, Row, Col} from "react-bootstrap";
import Slider from "react-slick";
import ReactPlayer from 'react-player/youtube'

const Kwame = () => {

    const images = require.context("./images", true);

    const imageList = images.keys().map(image => images(image));

    console.log(images);

    var settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 3500,
        cssEase:'ease-out',
        pauseOnHover: false
      };

    return (
      <>
      
        <Row id="sliderWrapper" className="no-margin no-padding align-items-center justify-content-content">
            <Col className="no-padding">
            <h1 className="no-margin"
            style={{fontSize: "100px"}}>DOPE <span style={{fontSize: "20px"}}>adjective: </span><span style={{fontSize: "25px"}}>Testament of how great something is.</span></h1>
            
            <Slider {...settings}>
      {imageList.map((image, index) => (
        <div key={index} className="slide-div">
            <Image key={index} src={image} alt= ""  />
        </div>
      ))
      }
    </Slider>
    <h1 className="no-margin"
            style={{fontSize: "100px", lineHeight:"50px"}}><span style={{fontSize: "25px"}}>A quality or feeling of strong or constant affection for and dedication to another. </span><span style={{fontSize: "20px"}}>noun: </span>LOVE</h1>
    <ReactPlayer 
        url='https://www.youtube.com/watch?v=3HcWf0-56Y0' 
        playing={true}
        loop={true}
        className="video-wrapper"
        
      />
            </Col>

        </Row>
          
    </>
    )
}

export default Kwame;